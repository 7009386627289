import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';

// Firebase dependency importations
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';

// importing analytics and firestore
import { AngularFireAnalyticsModule, ScreenTrackingService  } from '@angular/fire/analytics';

import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { SpeechVisionComponent } from '../pages/speech-vision/speech-vision.component';
import { RollEComponent } from '../pages/roll-e/roll-e.component';
import { DeepOwareComponent } from '../pages/deep-oware/deep-oware.component';
import { OnyxPageComponent } from '../pages/onyx-page/onyx-page.component';
import { FoodPageComponent } from '../pages/food-page/food-page.component';
import { CitizenPageComponent } from '../pages/citizen-page/citizen-page.component';
import { BitpayPageComponent } from '../pages/bitpay-page/bitpay-page.component';
import { ArtPageComponent } from '../pages/art-page/art-page.component';
import { SoftDevPageComponent } from '../pages/soft-dev-page/soft-dev-page.component';
import { Covid19PageComponent } from '../pages/covid19-page/covid19-page.component';
import { UpdatesPageComponent } from '../pages/updates-page/updates-page.component';
import { ResearchProjPageComponent} from '../pages/research-proj-page/research-proj-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SpeechVisionComponent,
    RollEComponent,
    DeepOwareComponent,
    OnyxPageComponent,
    FoodPageComponent,
    CitizenPageComponent,
    BitpayPageComponent,
    ArtPageComponent,
    SoftDevPageComponent,
    Covid19PageComponent,
    UpdatesPageComponent,
    ResearchProjPageComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    LayoutModule

  ],
  providers: [
    ScreenTrackingService,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
