import { Component, OnInit, } from '@angular/core';
import { Case } from '../../models/covid_case'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Subscriber, Subscription } from 'rxjs';
import 'firebase/firestore';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


declare var $: any;

@Component({
  selector: 'app-covid19-page',
  templateUrl: './covid19-page.component.html',
  styleUrls: ['./covid19-page.component.scss']
})
export class Covid19PageComponent implements OnInit {

  ngAfterViewInit() {
    $('.ui.basic.modal')
    .modal('show');
  }

  // json objects with data used to update firebase records
  worldwide_data = {
    type: "worldwide_data",
    total_cases: "117,514,622",
    active_cases: "21,875,645",
    recovered_cases: "93,032,155",
    deaths: "2,606,822",
    date: "08/03/21",
    time: "13:04 GMT",
  }

  ghana_data = {
    type: "ghana_data",
    total_cases: 86465,//   
    active_cases: 4866, //
    recovered_cases: 80952, // 
    deaths: 647, // 
    date: "08/03/21",
    chart_update_date: "08 Mar",
    time: "13:06 GMT",
    total_tests: "925611", // 12412 tests in a day (from 1st may to 2nd may)
  
    arrested: 406,
    in_custody: 103,
    bail: 248,
    processed_for_court: 41,
    fine: "12000 - 60000",
    term: "4-10yrs",

    // vertical lines on timeseries
    public_gathering_ban: "15/03/20",
    border_closure: "22/03/20",
    Accra_Ksi_lockdown: "30/03/20" ,
    Accra_Ksi_lockdown_end: "19/04/20",
    date_new_recovery_guidelines: "18/06/20",
    kotoka_reopening: "01/09/20"

    // sources
    // https://en.wikipedia.org/wiki/Timeline_of_the_COVID-19_pandemic_in_Ghana
    // https://en.wikipedia.org/wiki/COVID-19_pandemic_in_Ghana#Earliest_reported_cases
  }


  chart_data = {
    type: "chart",
    gender: {
      Male:   50150,
      Female:   36315, 
      // NA: 0
    },
    trans_type: {
      Imported:   1250,
      Local:   85215 , 
      // NA: 922
    },
    regional_cases: {
      Greater_accra: 48282,  //  
      Ashanti: 14954,         // 
      Western:5478,           //  
      Eastern_region: 3872,   // 
      Central_region: 3030,  // 

      Volta: 2091,            // 
      Upper_east: 1232,       //
      Oti: 308,              // 
      Northern_region: 1458,  // 
      Western_North: 808,    // 
      Bono_East:1237,                //
      Savannah:97,             // 
      Upper_west: 444,       // 
      Ahafo:666,
      North_east: 156,        //   
      Bono:1102              //


    }
  }


  timeseries_data = {
    type: "timeseries",
    //subject to change: when cases jumped to 834
    dates: ["Mar 12", "Mar 13", "Mar 14", "Mar 15", "Mar 16", "Mar 17", "Mar 18", "Mar 19", "Mar 20", "Mar 21", "Mar 22", "Mar 23", "Mar 24", "Mar 25", "Mar 26", "Mar 27", "Mar 28", "Mar 29", "Mar 30", "Mar 31", "Apr 01", "Apr 02", "Apr 03", "Apr 04","Apr 05", "Apr 06", "Apr 07", "Apr 08", "Apr 09", "Apr 10", "Apr 11", "Apr 12", "Apr 13","Apr 14", "Apr 15", "Apr 16", "Apr 17", "Apr 18","Apr 19","Apr 20","Apr 21",
    "Apr 22","Apr 23","Apr 24", "Apr 25", "Apr 26","Apr 27","Apr 28", "Apr 29","Apr 30", "May 1", "May 2", "May 3", "May 4", "May 5", "May 6", "May 7", "May 8","May 9","May 10","May 11","May 12","May 13","May 14","May 15", "May 16", "May 17","May 18","May 19","May 20","May 21","May 22","May 23","May 24","May 25","May 26","May 27","May 28","May 29","May 30","May 31","Jun 01","Jun 02","Jun 03","Jun 04","Jun 05","Jun 06","Jun 07","Jun 08",
    "Jun 09","Jun 10","Jun 11","Jun 12","Jun 13","Jun 14","Jun 15","Jun 16","Jun 17","Jun 18","Jun 19","Jun 20","Jun 21","Jun 22","Jun 23","Jun 24","Jun 25","Jun 26","Jun 27","Jun 28","Jun 29","Jun 30","Jul 01","Jul 02","Jul 03","Jul 04","Jul 05","Jul 06","Jul 07","Jul 08","Jul 09","Jul 10","Jul 11","Jul 12","Jul 13","Jul 14","Jul 15","Jul 16","Empty","Sep 4","Sep 5","Sep 6","Sep 7","Sep 8","Sep 9","Sep 10","Sep 11","Sep 12","Sep 13","Sep 14","Sep 15","Sep 16","Sep 17","Sep 18","Sep 19","Sep 20","Sep 21","Sep 22","Sep 23","Sep 24","Sep 25","Sep 26","Sep 27","Sep 28","Sep 29","Sep 30","Oct 01","Oct 02","Oct 03","Oct 04","Oct 05"],
    cases: [2, 4, 6, 6, 6, 7, 9, 11, 16, 21, 24, 27, 53, 68, 132, 137, 141, 152, 152, 161, 195, 204, 205, 214, 214, 287, 313, 313, 378, 408, 566, 566, 636, 641, 641, 641, 641, 834, 1042, 1042, 1042, 1279, 1279, 1279, 1550, 1550, 1671, 2074, 2074, 2074, 2169,2719,2719,3091,3091,3091,4012,4263,4263,4700,5127,5408,5530,5638,5735,5735,5918,6096,6269,6486,6617,6683,6808,6964,7117,7303,7616,7768,7881,8070,8297,8548,8885,9168,9462,9638,9910,10201,10358,10856,11118,11422,11717,11964,12193,12590,12929,13203,13717,14007,14154,14568,15013,15473,15834,16431,16742,17351,17741,18134,18134,19388,20085,21077,21968,22822,23463,23834,24248,24518,24988,25252,25430,26125,26572,27060,27667,"Empty",45012,45188,45313,45388,45434,45493,45572,45601,45655,45714,45760,45857,45877,46004,46062,46116,46153,46222,46353,46387,46444,46482,46626,46656,46694,46768,46803,46829,46829,46905,46947],
    recoverd: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 17, 83, 83, 83, 83, 99, 99, 99, 99, 134, 134, 134, 155, 155, 188, 212, 212, 212, 229,294,294,303,303,303,323,378,378,494,494,514,674,1460,1754,1754,1754,1773,1898,1951,1978,1998,2070,2097,2317,2412,2421,2540,2841,2947,2986,3132,3189,3457,3547,3636,3645,3755,3824,3921,3979,4156,4258,4258,4326,4410,4468,4548,10074,10473,10473,10907,11078,11431,11755,12257,12720,12994,13268,13550,13550,14330,14870,16070,17156,17564,18622,19212,19831,20187,21067,21397,21511,22270,22915,23044,23249,"Empty",43898,44042,44188,44267,44342,44471,44641,44679,44797,44896,44973,45029,45081,45153,45258,45290,45299,45417,45577,45618,45646,45651,45757,45942,45945,45964,46006,46060,46060,46223,46259],
    deaths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 2, 2, 3, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 8, 8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 10, 10, 10, 11, 11, 16, 17, 17, 17, 18,18,18,18,18,18,18,22,22,22,22,24,24,28,29,29,31,31,31,31,31,32,32,32,34,34,34,35,36,36,38,38,38,42,44,44,48,48,48,48,48,51,54,54,58,66,66,70,85,85,85,95,95,95,103,103,112,112,112,117,117,177,122,129,129,129,129,135,135,139,139,139,139,139,144,145,148,"Empty",283,283,283,285,286,289,292,294,294,294,295,295,297,297,297,297,299,299,299,299,299,301,301,301,301,301,303,303,303,306,306],
  }

  regional_data = {
    labels: ['Greater Accra', 'Northern', 'Ashanti', 'Upper West', 'Upper East', 'Eastern', 'Central', 'North East', 'Western', 'Volta','Western North', 'Oti', 'Bono','Savannah','Bono East','Ahafo'],
    datasets: [{
      label: 'Regional Confirmed Cases',
      data: [15, 3, 6, 3, 3, 3, 1, 1, 3, 4,0,1,2,2],
      backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(255, 159, 64, 0.6)', 'rgba(255, 205, 86, 0.6)', 'rgba(75, 192, 192, 0.6)',
        'rgba(54, 162, 235, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(196, 3, 3, 0.6)', 'rgba(201, 203, 207, 0.7)',
        'rgba(250, 175, 137, 0.6)', 'rgba(99, 226, 220, 0.6)','rgba(201, 203, 207, 0.7)','rgba(255, 205, 86, 0.6)',
        'rgba(54, 162, 235, 0.6)','rgba(54, 162, 235, 0.6)','rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
      borderWidth: 3,

    },

    ]
  }

  // regional bar chart data
  regional_data2 = {
    labels: ['Regional Confirmed Cases'],
    datasets: [{
      label: 'Greater Accra',
      // fill:false,
      backgroundColor: 'rgba(255, 99, 132, 0.6)',
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 1,
      data: [15],


    }, {
      label: 'Northern',
      backgroundColor: 'rgba(255, 159, 64, 0.6)',
      borderColor: 'rgb(255, 159, 64)',
      borderWidth: 1,
      data: [3],
    }, {
      label: 'Ashanti',
      backgroundColor: 'rgba(255, 205, 86, 0.6) ',
      borderColor: 'rgb(255, 205, 86) ',
      hoverBackgroundColor: 'rgba(255, 205, 86,0.7) ',
      hoverBorderColor: 'rgba(255, 205, 86) ',
      borderWidth: 1,
      data: [6],
    }, {
      label: 'Upper West',
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgb(75, 192, 192)',
      borderWidth: 1,
      data: [3],
    },
    {
      label: 'Upper East',
      backgroundColor: 'rgba(54, 162, 235, 0.6)',
      borderColor: 'rgb(54, 162, 235)',
      borderWidth: 1,
      data: [3],

    },
    {
      label: 'Eastern',
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
      borderColor: 'rgb(153, 102, 255)',
      borderWidth: 1,
      data: [3],
    },
    {
      label: 'Central',
      backgroundColor: 'rgba(196, 3, 3, 0.6)',
      borderColor: 'rgb(196, 3, 3)',
      borderWidth: 1,
      data: [1],
    },
    {
      label: 'N/A',
      backgroundColor: 'rgba(201, 203, 207, 0.7)',
      borderColor: 'rgb(201, 203, 207)',
      borderWidth: 1,
      data: [65],
    }]




  };

  // gender doughnut chart data
  gender_data = {
    datasets: [{
      data: [8, 2, 1],
      backgroundColor: ['rgba(255, 99, 132,0.8)', 'rgb(255, 205, 86)', 'rgb(201, 203, 207) '],
      // datalabels: {color: '#FFFFFF'}
    }],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
      'Male',
      'Female',
      'N/A'
    ],


  };

  // transmission bar chart data
  transmission_type_data = {
    labels: ['Transmission Type'],
    datasets: [{

      label: 'Imported',
      // fill:false,
      backgroundColor: 'rgba(255, 205, 86, 0.7)',
      borderColor: 'rgb(255, 205, 86)',
      borderWidth: 1,
      data: [15],


    }, {
      label: 'Local',
      backgroundColor: 'rgba(255, 99, 132, 0.7)',
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 1,
      data: [3],
      datalabels: {
        color: '#FFFFFF'
      }
    }
      // {
      //   label: 'N/A',
      //   backgroundColor: 'rgba(201, 203, 207, 0.7) ',
      //   borderColor: 'rgb(201, 203, 207) ',

      //   hoverBackgroundColor: 'rgba(201, 203, 207,0.7) ',
      //   hoverBorderColor: 'rgba(201, 203, 207) ',
      //   borderWidth: 1,
      //   data: [6],

      // }
    ]
  };

  // Case timeseries graph data
  timeseries_chart_data = {
    labels: ["Mar 12", "Mar 13", "Mar 14", "Mar 15", "Mar 16", "Mar 17", "Mar 18", "Mar 19", "Mar 20", "Mar 21", "Mar 22", "Mar 23", "Mar 24", "Mar 25", "Mar 26"],
    datasets: [
      {
        label: "Confirmed Cases",
        data: [2, 4, 6, 6, 6, 7, 9, 11, 16, 21, 24, 27, 53, 68, 132],
        // borderColor: "#3cba9f",
        borderColor: "#d43d6a",
        fill: false
      },
      {
        label: "Recoveries",
        data: [5, 24, 4, 56],
        borderColor: "#1a8162",
        fill: false
      },
      {
        label: "Deaths",
        data: [5, 34, 4, 60],
        borderColor: "#363636",
        fill: false
      },
    ]
  }

  //extra data on cases
  extra_case_data = {
    type: "extra_case_data",
    current_discharged: { count: 80952, percentage: 0 },
    current_mild: { count: 5423, percentage: 0 }, 
    current_critical: { count: 90, percentage: 0 },
    impacted_regions: { count: 16, percentage: 0 }
  }

  // observables to receive changes and updates through
  worldwide_stats: Observable<any>;

  ghana_stats: Observable<any>;

  chart_stats: Observable<any>;

  ages: Observable<any[]>;

  // from firestore
  current_min_age: any
  current_max_age: any
  current_age_sum: any
  current_age_count: any
  current_avg_age: any
  total_tests: any

  minview_stats = []
  maxview_stats = []
  extra_stats = []

  new_age: number;
  new_date: string;
  new_casecount: number;

  // variables to hold chart objects
  regionChart: any;
  genderChart: any;
  trans_typeChart: any;
  timeseriesChart: any;

  //smallcharts
  genderChart2: any;
  trans_typeChart2: any;

  subscriptions: Subscription[] = [];

  /** Default Angular life cycle method; Constructor **/
  constructor(private firestore: AngularFirestore) {

    // /**calc percentages**/
    console.log("Female",this.case_calc(42,86465))
    console.log("Male",this.case_calc(58,86465))

    // console.log("Imported",this.case_calc(12,5408))    //23% as at 18th April
    // console.log("Local",this.case_calc(88,5408))   //77% as at 18th April

    // in case you want it enabled only for a few charts, you first need to unregister it globally:
    Chart.plugins.unregister(ChartDataLabels);
    // Subscribe to value changes/updates

    this.ages = firestore.collection('GH-Covid-19/database/ages').valueChanges();

    this.worldwide_stats = firestore.doc("GH-Covid-19/database/worldwide/#01worldwide_data").valueChanges()

    this.ghana_stats = firestore.doc("GH-Covid-19/database/ghana/#01ghana_data").valueChanges()

    this.chart_stats = firestore.doc("GH-Covid-19/database/charts/#01chart_data").valueChanges()


    // retrieve data from observables by subscribing to various firebase observables. Using an array to make it easy to unsuscribe to prevent memory leak 
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/ages/#01ages_count").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/ages/#01ages_min").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/ages/#01ages_max").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/ages/#01ages_sum").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/ages/#01ages_avg").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/charts/#01chart_data").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/charts/#01timeseries_data").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.firestore.doc("GH-Covid-19/database/ghana/#01extra_case_data").valueChanges().subscribe(data => this.save_item(data)))
    this.subscriptions.push(this.ghana_stats.subscribe(data => this.save_item(data)))

    // will be disabled for uploaded version
    // this.updateData()



    // initial data push
    // this.firestore.doc("GH-Covid-19/database/ages/#01ages_count").set({ "value": 0, "type": "count" })
    // this.firestore.doc("GH-Covid-19/database/ages/#01ages_min").set({ "value": 0, "type": "min" })
    // this.firestore.doc("GH-Covid-19/database/ages/#01ages_max").set({ "value": 0, "type": "max" })
    // this.firestore.doc("GH-Covid-19/database/ages/#01ages_sum").set({ "value": 0, "type": "sum" })
    // this.firestore.doc("GH-Covid-19/database/ages/#01ages_avg").set({ "value": 0, "type": "avg" })

  

  }

  /** Callback method on subscription to value change of firestore items. 
    * Allows extracting and storage of data outside observable. Stat values and graph updates done here as well **/
  save_item(data) {

    if (data.type == "count") {
      this.current_age_count = data.value
      // console.log(this.current_age_count)
    }
    if (data.type == "min") {
      this.current_min_age = data.value
      // console.log(this.current_min_age)
    }
    if (data.type == "max") {
      this.current_max_age = data.value
      // console.log(this.current_max_age)
    }
    if (data.type == "avg") {
      this.current_avg_age = data.value
      // console.log(this.current_avg_age)
    }
    if (data.type == "sum") {
      this.current_age_sum = data.value
      // console.log(this.current_age_sum)
    }
    if (data.type == "ghana_data") {
      this.total_tests = data.total_tests
      // console.log(this.total_tests)
    }

    //collate data into extra_stat array for publishing in frontend
    if (data.type == "extra_case_data") {
      this.extra_stats = [
        { title: "Discharged ", value: data.current_discharged.count, color: "green", perc: data.current_discharged.percentage },

        { title: "Mild Cases ", value: data.current_mild.count, color: "yellow", perc: data.current_mild.percentage },

        { title: "Critical Cases", value: data.current_critical.count, color: "red", perc: data.current_critical.percentage },

        { title: "Impacted Regions ", value: data.impacted_regions.count, color: "grey", perc: data.current_mild.percentage },
      ]
    }

    //collate data into stats array for publishing in frontend
    this.minview_stats = [
      { title: "Known Min Age", value: this.current_min_age },

      { title: "Known Max Age", value: this.current_max_age },

      { title: "Known Average Age", value: this.current_avg_age },

      { title: "Total Tests", value: this.total_tests, },
    ]

    this.maxview_stats = [
      { title: "Min Age", value: this.current_min_age },

      { title: "Max Age", value: this.current_max_age },

      { title: "Average Age", value: this.current_avg_age },

      { title: "Total Tests", value: this.total_tests, },
    ]

    //collate data into chart stats array for graphing in frontend

    if (data.type == "chart") {

      // update data in regional chart with data from firebase 
      // this.regionChart.data.datasets[0].data[0] = data.regional_cases.Greater_accra
      // this.regionChart.data.datasets[1].data[0] = data.regional_cases.Northern_region
      // this.regionChart.data.datasets[2].data[0] = data.regional_cases.Ashanti
      // this.regionChart.data.datasets[3].data[0] = data.regional_cases.Upper_west
      // this.regionChart.data.datasets[4].data[0] = data.regional_cases.Upper_east
      // this.regionChart.data.datasets[5].data[0] = data.regional_cases.Eastern_region 
      // this.regionChart.data.datasets[6].data[0] = data.regional_cases.Central_region
      // this.regionChart.data.datasets[7].data[0] = data.regional_cases.N_A
      this.regionChart.data.datasets.forEach((dataset) => {
        dataset.data[0] = data.regional_cases.Greater_accra
        dataset.data[1] = data.regional_cases.Northern_region
        dataset.data[2] = data.regional_cases.Ashanti
        dataset.data[3] = data.regional_cases.Upper_west
        dataset.data[4] = data.regional_cases.Upper_east
        dataset.data[5] = data.regional_cases.Eastern_region
        dataset.data[6] = data.regional_cases.Central_region
        dataset.data[7] = data.regional_cases.North_east
        dataset.data[8] = data.regional_cases.Western
        dataset.data[9] = data.regional_cases.Volta
        dataset.data[10] = data.regional_cases.Western_North
        dataset.data[11] = data.regional_cases.Oti
        dataset.data[12] = data.regional_cases.Bono 
        dataset.data[13] = data.regional_cases.Savannah
        dataset.data[14] = data.regional_cases.Bono_East
        dataset.data[15] = data.regional_cases.Ahafo
        // console.log(dataset.data)
      })
      // this.regionChart.data.datasets[0].data

      this.regionChart.update();

      // update data in gender chart with data from firebase
      this.genderChart.data.datasets.forEach((dataset) => {
        dataset.data[0] = data.gender.Male
        dataset.data[1] = data.gender.Female
        dataset.data[2] = data.gender.NA
        // console.log(data.gender)
      });
      this.genderChart.update();

      //second genderchart small screen
      this.genderChart2.data.datasets.forEach((dataset) => {
        dataset.data[0] = data.gender.Male
        dataset.data[1] = data.gender.Female
        dataset.data[2] = data.gender.NA
        // console.log(data.gender)
      });
      this.genderChart2.update();

      // update data in transmission type chart with data from firebase
      this.trans_typeChart.data.datasets[0].data[0] = data.trans_type.Imported
      this.trans_typeChart.data.datasets[1].data[0] = data.trans_type.Local
      // this.trans_typeChart.data.datasets[2].data[0] = data.trans_type.NA

      this.trans_typeChart.update();

      // update data in transmission type chart with data from firebase
      this.trans_typeChart2.data.datasets[0].data[0] = data.trans_type.Imported
      this.trans_typeChart2.data.datasets[1].data[0] = data.trans_type.Local
      // this.trans_typeChart2.data.datasets[2].data[0] = data.trans_type.NA

      this.trans_typeChart2.update();
    }

    //collate data for timeseries graph updates in frontend
    if (data.type == "timeseries") {
      // update data in timeseries chart with data from firebase
      this.timeseriesChart.data.labels = data.dates
      this.timeseriesChart.data.datasets[0].data = data.cases
      this.timeseriesChart.data.datasets[1].data = data.recoverd
      this.timeseriesChart.data.datasets[2].data = data.deaths

      this.timeseriesChart.update();
    }

  }

  /** Method to calculate actual case figures given percentages **/
  case_calc(perc_num, whole) {
    return ((perc_num * whole) / 100)
  }

  /** Method to add new points to the timeseries graph tracking dates and total confirmed cases **/
  add_date_case() {
    // add new entries to dates and cases arrays in the timeseries_data object
    this.timeseries_data.dates.push(this.new_date)
    this.timeseries_data.cases.push(Number(this.new_casecount))

    //update timeseries graph data
    this.firestore.doc("GH-Covid-19/database/charts/#01timeseries_data").set(this.timeseries_data)

  }

  /** Method to take new age input and compute various age related statistics.
   * Then add this data to firebase **/
  add_age() {

    let new_age_count = this.current_age_count + 1
    let new_age_sum = Number(this.current_age_sum) + Number(this.new_age)
    let new_avg_age = (new_age_sum / new_age_count).toFixed(1)

    // add new age
    // this.firestore.collection('GH-Covid-19/database/ages').add({value:this.new_age})

    //update age count 
    this.firestore.doc("GH-Covid-19/database/ages/#01ages_count").set({ value: new_age_count, type: "count" })

    //update min age 
    if (this.new_age < this.current_min_age) {
      this.firestore.doc("GH-Covid-19/database/ages/#01ages_min").set({ value: this.new_age, type: "min" })
    }

    //update max age 
    if (this.new_age > this.current_max_age) {
      this.firestore.doc("GH-Covid-19/database/ages/#01ages_max").set({ value: this.new_age, type: "max" })
    }

    //update age sum
    this.firestore.doc("GH-Covid-19/database/ages/#01ages_sum").set({ value: new_age_sum, type: "sum" })


    //update avg age 
    this.firestore.doc("GH-Covid-19/database/ages/#01ages_avg").set({ value: new_avg_age, type: "avg" })

  }


  /** Method to update data for world and ghana cards using data in worldwide_data &  worldwide_data json objects. 
   * Also updates default timeseries data in firebase using data in timeseries_data json object **/
  updateData() {

    //  add new case
    // this.firestore.collection('cases').add(this.samplecase)

    // add new age
    // this.firestore.collection('ages').add({value:58})

    //update worldwide data
    this.firestore.doc("GH-Covid-19/database/worldwide/#01worldwide_data").set(this.worldwide_data)

    //update ghana data
    this.firestore.doc("GH-Covid-19/database/ghana/#01ghana_data").set(this.ghana_data)

    //update chart_data
    this.firestore.doc("GH-Covid-19/database/charts/#01chart_data").set(this.chart_data)

    //update timeseries graph data
    this.firestore.doc("GH-Covid-19/database/charts/#01timeseries_data").set(this.timeseries_data)

    //update extra case stats graph data
    this.firestore.doc("GH-Covid-19/database/ghana/#01extra_case_data").set(this.extra_case_data)

  }

  /** Default Angular method life cycle, called everytime page is changed
   * JQuery stuff expected to autoload is done here, it runs multiple times so implement a check **/




  /** Default Angular method life cycle, called when view initializes.
   * Graph objects created and initialized here **/
  ngOnInit() {

    // scroll to top when page is opened
    window.scrollTo(0, 0);

    // regional cases pie chart
    this.regionChart = new Chart('regionChart', {
      type: 'bar',
      data: this.regional_data,
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            font: { weight: 'bold' }
          }
        },
        responsive: true,
        maintainAspectRatio: false,  //fixed responsive problem
        legend: {
          display: false,
          // position: 'bottom',
          labels: {
            // fontColor: "#000080",
          }
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });

    // gender pie chart
    this.genderChart = new Chart('genderChart', {
      type: 'doughnut',
      data: this.gender_data,
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            // color: '#FFFFFF', 
            font: { size: "15.5" }
          }
        },
        responsive: true,
        maintainAspectRatio: false,  //fixed responsive problem
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    });

    // gender pie chart (small screen)
    this.genderChart2 = new Chart('genderChart2', {
      type: 'doughnut',
      data: this.gender_data,
      plugins: [ChartDataLabels],
      options: {
        //   legendCallback: function(chart) {
        //     // Return the HTML string here.
        // },
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            // color: '#FFFFFF',
            font: { size: "15.5" }
          }
        },
        responsive: true,
        maintainAspectRatio: false,  //fixed responsive problem
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    });

    // transmission type bar chart
    this.trans_typeChart = new Chart('trans_typeChart', {
      type: 'bar',
      data: this.transmission_type_data,
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            // color: '#FFFFFF',
            font: { size: "15.5" }
          }
        },
        responsive: true,
        maintainAspectRatio: false,  //fixed responsive problem
        legend: {
          display: true,
          // position: 'bottom',
          labels: {
            // fontColor: "#000080",
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });

    // transmission type bar chart (small screen)
    this.trans_typeChart2 = new Chart('trans_typeChart2', {
      type: 'bar',
      data: this.transmission_type_data,
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            // color: '#FFFFFF',
            font: { size: "15.5" }
          }
        },
        responsive: true,
        maintainAspectRatio: false,  //fixed responsive problem
        legend: {
          display: true,
          // position: 'bottom',
          labels: {
            // fontColor: "#000080",
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }

      }
    });

    // case timeseries chart
    this.timeseriesChart = new Chart('timeseriesChart', {
      type: 'line',
      data: this.timeseries_chart_data,
      options: {
        legend: {
          display: true,
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true
          }],
        },

        // plugins: {
        //   annotation: {
        //     annotations: [
        //       {
        //         drawTime: 'afterDatasetsDraw', 
        //         type: "line",
        //         mode: "vertical",
        //         scaleID: "x-axis-0",
        //         value: "Mar 28",
        //         borderColor: "red",
        //         label: {
        //           content: "TODAY",
        //           enabled: true,
        //           position: "top"
        //         }
        //       }
        //     ]
        //   }
        // }

        
      }
    });
  }

  /** Default Angular method life cycle, called when view closes.
 * Closed all subscriptions to prevent memory leak **/
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())

  }
}
