import { Component, OnInit } from '@angular/core';
import { software_projects } from '../../mocks/softwareprojects';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-soft-dev-page',
  templateUrl: './soft-dev-page.component.html',
  styleUrls: ['./soft-dev-page.component.scss']
})
export class SoftDevPageComponent implements OnInit {

  software_projects = software_projects

  constructor(private router: Router) { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

  viewProject(event, id) {
    this.router.navigate([software_projects[id].project_page])
  }

 

}
