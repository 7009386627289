import { SoftwareProject } from '../models/software-project'

export const software_projects: SoftwareProject[] = [


    {
        id: 0,
        title: "Gh-Covid-19: Tracking data from Ghanaian Covid-19 cases",
        cover_art: "assets/project_covid-19/covid_splash.png",
        project_page: "/covid-19",
        lazyloadimg: 'orangecoral',
        date:"March, 2021",

        cover_art_320: 'assets/project_covid-19/320w/covid_splash.jpg',
        cover_art_400: 'assets/project_covid-19/400w/covid_splash.jpg',
        cover_art_600: 'assets/project_covid-19/600w/covid_splash.jpg',
        cover_art_960: 'assets/project_covid-19/960w/covid_splash.jpg',
        cover_art_1024: 'assets/project_covid-19/1024w/covid_splash.jpg',
        cover_art_1200: 'assets/project_covid-19/1200w/covid_splash.jpg'
    }, 
    {
        id: 1,
        title: "Project Onyx: Computer vision based energy optimization software",
        cover_art: "assets/project_onyx/onyx_splash1.png",
        project_page: "/onyx-page",
        lazyloadimg: 'scooter',
        date:"2017",

        cover_art_320: 'assets/project_onyx/320w/onyx_splash1.jpg',
        cover_art_400: 'assets/project_onyx/400w/onyx_splash1.jpg',
        cover_art_600: 'assets/project_onyx/600w/onyx_splash1.jpg',
        cover_art_960: 'assets/project_onyx/960w/onyx_splash1.jpg',
        cover_art_1024: 'assets/project_onyx/1024w/onyx_splash1.jpg',
        cover_art_1200: 'assets/project_onyx/1200w/onyx_splash1.jpg'

    },

    {
        id: 2,
        title: "Project Food: Cross platform ecosystem for food vendors",
        cover_art: "assets/project_food/food_splash.png",
        project_page: "/food-page",
        lazyloadimg: 'brightred',
        date:"2017",

        cover_art_320: 'assets/project_food/320w/food_splash.jpg',
        cover_art_400: 'assets/project_food/400w/food_splash.jpg',
        cover_art_600: 'assets/project_food/600w/food_splash.jpg',
        cover_art_960: 'assets/project_food/960w/food_splash.jpg',
        cover_art_1024: 'assets/project_food/1024w/food_splash.jpg',
        cover_art_1200: 'assets/project_food/1200w/food_splash.jpg'

    },



    {
        id: 3,
        title: "Project Citizen: Crowd source crime/disaster response system",
        cover_art: "assets/project_citizen/citizen_splash1.png",
        project_page: "/citizen-page",
        lazyloadimg: 'quepal',
        date:"2017",

        cover_art_320: 'assets/project_citizen/320w/citizen_splash1.jpg',
        cover_art_400: 'assets/project_citizen/400w/citizen_splash1.jpg',
        cover_art_600: 'assets/project_citizen/600w/citizen_splash1.jpg',
        cover_art_960: 'assets/project_citizen/960w/citizen_splash1.jpg',
        cover_art_1024: 'assets/project_citizen/1024w/citizen_splash1.jpg',
        cover_art_1200: 'assets/project_citizen/1200w/citizen_splash1.jpg'

    },

    {
        id: 4,
        title: "Project BitPay: Peer to peer payment platform",
        cover_art: "assets/project_bitPay/bitpay_splash2.png",
        project_page: "/bitpay-page",
        lazyloadimg: 'blurrybeach',
        date:"2017",

        cover_art_320: 'assets/project_bitPay/320w/bitpay_splash2.jpg',
        cover_art_400: 'assets/project_bitPay/400w/bitpay_splash2.jpg',
        cover_art_600: 'assets/project_bitPay/600w/bitpay_splash2.jpg',
        cover_art_960: 'assets/project_bitPay/960w/bitpay_splash2.jpg',
        cover_art_1024: 'assets/project_bitPay/1024w/bitpay_splash2.jpg',
        cover_art_1200: 'assets/project_bitPay/1200w/bitpay_splash2.jpg'

    },


 
]
