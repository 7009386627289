import {ResearchProject} from '../models/research-project'
 
export const research_projects: ResearchProject[] = [

    {
        id: 0,
        title: "Vehicle Autonomy: State of the art, opportunities and challenges for adaptation in Africa",
        cover_art: "assets/misc/4.jpg",
        project_page : "/speechvision-page",
        status: 'Completed',
        // status: 'In Progress',
        lazyloadimg: 'brightred', 
        date:'2018',

        cover_art_320: 'assets/misc/320w/4.jpg',
        cover_art_400: 'assets/misc/400w/4.jpg',
        cover_art_600: 'assets/misc/600w/4.jpg',
        cover_art_960: 'assets/misc/960w/4.jpg',
        cover_art_1024: 'assets/misc/1024w/4.jpg',
        cover_art_1200: 'assets/misc/1200w/4.jpg'
    },

    {
        id: 1,
        title: "Deep Oware: Learning from Experience",
        cover_art: "assets/misc/3.jpg",
        project_page:"/deepoware-page",
        status: 'Completed',
        lazyloadimg:'blurrybeach',
        date:'2018',

        cover_art_320: 'assets/misc/320w/3.jpg',
        cover_art_400: 'assets/misc/400w/3.jpg',
        cover_art_600: 'assets/misc/600w/3.jpg',
        cover_art_960: 'assets/misc/960w/3.jpg',
        cover_art_1024: 'assets/misc/1024w/3.jpg',
        cover_art_1200: 'assets/misc/1200w/3.jpg'
    },

    {
        id: 2,
        title: "Affordable Autonomous Vehicle Development Platform",
        cover_art: "assets/misc/1.jpg",
        project_page:"/rolle-page",
        status: 'Published',
        lazyloadimg:'quepal',
        date:'2018',

        cover_art_320: 'assets/misc/320w/1.jpg',
        cover_art_400: 'assets/misc/400w/1.jpg',
        cover_art_600: 'assets/misc/600w/1.jpg',
        cover_art_960: 'assets/misc/960w/1.jpg',
        cover_art_1024: 'assets/misc/1024w/1.jpg',
        cover_art_1200: 'assets/misc/1200w/1.jpg'
    },
    

    {
        id: 3,
        title: "Chiromancer: Open Source Sign Language Recognition Platform",
        cover_art: "assets/misc/2.jpg",
        project_page: "speechvision-page",
        status: 'Completed',
        lazyloadimg:'scooter',
        date:'2017',

        cover_art_320: 'assets/misc/320w/2.jpg',
        cover_art_400: 'assets/misc/400w/2.jpg',
        cover_art_600: 'assets/misc/600w/2.jpg',
        cover_art_960: 'assets/misc/960w/2.jpg',
        cover_art_1024: 'assets/misc/1024w/2.jpg',
        cover_art_1200: 'assets/misc/1200w/2.jpg'
    },


    
]