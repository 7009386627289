import { Component, OnInit } from '@angular/core';
import { research_projects } from '../../mocks/researchprojects';
declare var $: any;

@Component({
  selector: 'app-roll-e',
  templateUrl: './roll-e.component.html',
  styleUrls: ['./roll-e.component.scss']
})
export class RollEComponent implements OnInit {

  project = research_projects[2]
 

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
    $('.ui.embed').embed();
  }

}
