import { Event } from '../models/event'

export const events: Event[] = [
    {
        id: 0,
        title: "Designed and taught Artificial Intelligence summer course at Brown University",
        type: "Career",
        date: "August 2022",
        link: "https://apps.precollege.brown.edu/catalog/course.php?course_code=CECS0915"

    },
    {
        id: 1,
        title: "Started CS Ph.D at Brown University advised by Prof. George Konidaris (The Intelligent Robot Lab)",
        type: "Career",
        date: "August 2021",
        link: "http://irl.cs.brown.edu/"

    },
    {
        id: 2,
        title: "Joined Nokwary as Principal Machine Learning Engineer",
        type: "Career",
        date: "August 2020",
        link: "https://nokwary.com/"

    },
    {
        id: 3,
        title: "Speaker at Biennale International Design Inaugural Conference",
        type: "Conference",
        date: "March 2019",
        link: "https://www.biennale-design.com/saint-etienne/2019/fr/home/?article=-4-l-intelligence-artificielle-1669"

    },

    {
        id: 4,
        title: "Run an Autonomous Vehicle Workshop [RollE] at ESADSE",
        type: "Workshop",
        date: "March 2019",
        link: "https://biennale-design.com/saint-etienne/2019/fr/programmation/?event=workshop-vehicule-autonome-rolle-618"

    },


    {
        id: 5,
        title: "Presented at the 7th IEEE International Conference on Adaptive Technology (ICAST)",
        type: "Conference",
        date: "August 2018",
        link: "http://ieeeicast.ghsociety.org/index.php"

    },

]



