import { Component, OnInit } from '@angular/core';
import { research_projects } from '../../mocks/researchprojects';
@Component({
  selector: 'app-deep-oware',
  templateUrl: './deep-oware.component.html',
  styleUrls: ['./deep-oware.component.scss']
})
export class DeepOwareComponent implements OnInit {


  project = research_projects[1]


  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

}
