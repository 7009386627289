import { Component, OnInit, AfterViewInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-art-page',
  templateUrl: './art-page.component.html',
  styleUrls: ['./art-page.component.scss']
})
export class ArtPageComponent implements OnInit, AfterViewInit  {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);

    // // disable right click to protect my art work
    // window.addEventListener('contextmenu', function (e) { 
    //   // do something here... 
    //   e.preventDefault(); 
    // }, false);
}

ngAfterViewInit() {
    
}




}