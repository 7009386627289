import { Component, OnInit } from '@angular/core';
import { software_projects } from '../../mocks/softwareprojects';

declare var $: any;

@Component({
  selector: 'app-food-page',
  templateUrl: './food-page.component.html',
  styleUrls: ['./food-page.component.scss']
})
export class FoodPageComponent implements OnInit {

  project = software_projects[2]
//   {
//     id: 2,
//     title: "Project Food: Cross platform ecosystem for food vendors",
//     cover_art: "assets/project_food/food_splash.png",
//     lazyloadimg: 'brightred'
// }

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

}
