import { Component, OnInit } from '@angular/core';
import { software_projects } from '../../mocks/softwareprojects';

@Component({
  selector: 'app-bitpay-page',
  templateUrl: './bitpay-page.component.html',
  styleUrls: ['./bitpay-page.component.scss']
})
export class BitpayPageComponent implements OnInit {

  project = software_projects[4]
//   {
//     id: 2,
//     title: "Project BitPay: Peer to peer payment platform",
//     cover_art: "assets/project_bitPay/bitpay_splash2.png",
//     lazyloadimg:'blurrybeach'
// }


  constructor() { 


}

  ngOnInit() {
    window.scrollTo(0,0);
  }

}
