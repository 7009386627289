import { Component, OnInit,AfterViewInit } from '@angular/core';
import { software_projects } from '../../mocks/softwareprojects';
declare var $:any

@Component({
  selector: 'app-citizen-page',
  templateUrl: './citizen-page.component.html',
  styleUrls: ['./citizen-page.component.scss']
})
export class CitizenPageComponent implements OnInit,AfterViewInit {

  project = software_projects[3]
//   {
//     id: 3,
//     title: "Project Citizen: Crowd source crime/disaster response system",
//     cover_art: "assets/project_citizen/citizen_splash1.png",
//     lazyloadimg:'quepal'
// }

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

  ngAfterViewInit(){
 
  }

  showModal(){
    // console.log("clicked")
    // $('#img_modal')
    // .modal('show')
  }

}
