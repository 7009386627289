import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { software_projects } from '../../mocks/softwareprojects';

declare var $: any;

@Component({
  selector: 'app-onyx-page',
  templateUrl: './onyx-page.component.html',
  styleUrls: ['./onyx-page.component.scss']
})
export class OnyxPageComponent implements OnInit,AfterViewInit {

  project =  software_projects[1]
//   {
//     id: 1,
//     title: "Project Onyx: Computer Vision based Energy Optimization Software",
//     cover_art: "assets/project_onyx/onyx_splash1.png",
//     lazyloadimg:'scooter'
// }

  constructor() { }

  ngOnInit() {
    window.scrollTo(0,0);
  }

  ngAfterViewInit(){
    $('.ui.embed').embed();
  }



}
