import { Component, OnInit } from '@angular/core';
import { events } from '../../mocks/events';

@Component({
  selector: 'app-updates-page',
  templateUrl: './updates-page.component.html',
  styleUrls: ['./updates-page.component.scss']
})
export class UpdatesPageComponent implements OnInit {

  events = events

  constructor() { }

  ngOnInit() {
  }

}
