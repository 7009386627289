import { Component, OnInit } from '@angular/core';
import { research_projects } from '../../mocks/researchprojects';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-research-proj-page',
  templateUrl: './research-proj-page.component.html',
  styleUrls: ['./research-proj-page.component.scss']
})
export class ResearchProjPageComponent implements OnInit {

  research_projects = research_projects
  uncompleted_project = research_projects[0]

  constructor(private router: Router) { }


  ngOnInit() {
    window.scrollTo(0,0);
  }


  viewProject(event, id) {
    if (id == 0) {
      $('.ui.modal')
      .modal('show');
    }
    else{
      this.router.navigate([research_projects[id].project_page])
    }
  
  }

}
