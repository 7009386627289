import { Component, OnInit } from '@angular/core';
import { research_projects } from '../../mocks/researchprojects';

declare var $: any;

@Component({
  selector: 'app-speech-vision',
  templateUrl: './speech-vision.component.html',
  styleUrls: ['./speech-vision.component.scss']
})
export class SpeechVisionComponent implements OnInit {

  project = research_projects[3]
  



  constructor() { }

  ngOnInit() {
    //fixes persistent scrolling down on pages because of scroll position of previous page. Always starts from the top
    window.scrollTo(0,0);
  }

}
