import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { SpeechVisionComponent } from '../pages/speech-vision/speech-vision.component';
import { RollEComponent } from '../pages/roll-e/roll-e.component';
import { DeepOwareComponent } from '../pages/deep-oware/deep-oware.component';
import { OnyxPageComponent } from '../pages/onyx-page/onyx-page.component';
import { FoodPageComponent } from '../pages/food-page/food-page.component';
import { CitizenPageComponent } from '../pages/citizen-page/citizen-page.component';
import { BitpayPageComponent } from '../pages/bitpay-page/bitpay-page.component';
import { ArtPageComponent } from '../pages/art-page/art-page.component';
import { SoftDevPageComponent } from '../pages/soft-dev-page/soft-dev-page.component';
import { Covid19PageComponent } from '../pages/covid19-page/covid19-page.component';
import { UpdatesPageComponent } from '../pages/updates-page/updates-page.component';
import { ResearchProjPageComponent} from '../pages/research-proj-page/research-proj-page.component';

const routes: Routes = [

  {path: 'home-page', component: HomePageComponent},
  {path: 'speechvision-page', component: SpeechVisionComponent},
  {path: 'deepoware-page', component: DeepOwareComponent},
  {path: 'rolle-page', component: RollEComponent},
  {path: 'onyx-page', component: OnyxPageComponent},
  {path: 'food-page', component: FoodPageComponent},
  {path: 'citizen-page', component: CitizenPageComponent},
  {path: 'bitpay-page', component: BitpayPageComponent},
  {path: 'art-page', component: ArtPageComponent},
  {path: 'soft_dev-page', component: SoftDevPageComponent},
  {path: 'covid-19', component: Covid19PageComponent},
  {path: 'updates-page', component: UpdatesPageComponent},
  {path: 'research_proj-page', component: ResearchProjPageComponent},
  // specifies that all empty paths should redirect to homepage (sets homepage as rootpage)
  { path: '', redirectTo: '/home-page', pathMatch: 'full' },
  //redirects wrong paths to home-page
  { path: '**', redirectTo: '/home-page', pathMatch: 'full' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
